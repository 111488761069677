<template>
    <div class="flex h-screen mt-100 bg-white mb-130" style="margin-top: 100px; height: fit-content">
        <!-- Sidebar -->
        <Sidebar style="margin-right: 10px" />

        <!-- Main Content -->
        <MainContent style="margin-top: 10px" />
    </div>
</template>

<script>
import Sidebar from "../components/SidebarAccount.vue";
import MainContent from "../components/AccountMain.vue";
import { useStore } from "../store/index";

export default {
    components: {
        Sidebar,
        MainContent,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    mounted() {},
};
</script>

<style scoped>
/* Add global styles if needed */
</style>
