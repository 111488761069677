<template>
    <InviteTeammate :showEmail="showEmail" :toggleEmail="toggleEmail" />
    <CompareModal :compareModal="compareModal" :toggleCompare="toggleCompare" :candidates="Store.evaluations" :projectId="bestCandidate.projectId" />
    <div class="activityPageWrapper">
        <!-- <div v-if="isLoading" class="loader w-full h-full"> -->
        <LoadingComponent v-if="isLoading" />
        <!-- </div> -->
        <div v-else class="activityContainer">
            <!-- <div class="exit-area" @click="toggleActivity"></div> -->
            <div class="activityContent">
                <div class="flex flex-row justify-between my-8 mb-[3%]">
                    <!-- <h1 class="font-bold text-2xl w-full text-left title2">{{ $t("Activity") }}</h1> -->
                    <div class="w-full flex justify-between">
                        <div class="flex flex-row items-center justify-start">
                            <h1 class="font-bold text-2xl w-full text-left title">{{ $t("Activity") }}</h1>
                            <Popper :content="$t('invite team members')" placement="bottom" :hover="true">
                                <button class="rounded-full text-lg text-[#7D8FB3]" @click="toggleEmail">
                                    <font-awesome-icon :icon="['fas', 'user-plus']" />
                                </button>
                            </Popper>
                        </div>
                        <!-- <button class="button" @click="this.$router.push('/dashboard')">Dashboard</button> -->
                    </div>
                    <!-- <button class="compareBtn">Compare talent</button> -->
                    <div v-if="Store.getAllProjectList.length > 0" class="input_group select">
                        <label class="required select_label" for="assessment">{{ $t("Project") }} :</label>

                        <select v-model="project" @change="getBestCandidate(project)" style="width: max-content">
                            <!-- <option value="">{{ $t("Select") }}</option> -->
                            <option v-for="project in Store.getAllProjectList" :key="project.id" :value="project.id" :selected="index === 0">
                                {{ project.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="!candidateInfo" class="noCandidates">
                    <!-- <div class="w-full flex justify-between p-[2%] mt-[5%]">
                    <h2>{{ $t("Activity") }}</h2>
                    <button @click="this.$router.push('/dashboard')">{{ $t("Dashboard") }}</button>
                </div> -->
                    <div class="w-full p-[2%] mt-[2%] secondText flex flex-col items-center justify-start">
                        <img loading="lazy" decoding="async" src="@/assets/Images/emptyActivity.svg" alt="GO PLATFORM" class="" />
                        <h1>{{ $t("NO DATA") }}</h1>
                        <h3>
                            {{ $t("Select a project, and assess your talents!") }} <span>{{ $t("Slack Channel") }}</span>
                        </h3>
                    </div>
                </div>
                <div v-else>
                    <div class="grid-row-widgets">
                        <div class="widget background-2">
                            <div>
                                <span class="widget-title">{{ $t("Score") }} </span>
                                <span class="widget-info">{{ calculateScore() }} %</span>
                            </div>
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/Chart_100.svg" alt="bar-chart-icon" />
                        </div>
                        <div class="widget">
                            <div>
                                <span class="widget-title">{{ $t("Rank") }}</span>
                                <span class="widget-info">{{ $t("1st") }}</span>
                            </div>
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-graph.svg" alt="bar-graph" />
                        </div>

                        <div class="widget">
                            <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-chart-icon.svg" alt="bar-chart-icon" />
                            <div style="width: 70%">
                                <span class="widget-title">{{ $t("Assessment time") }}</span>
                                <span class="widget-info">21</span>
                            </div>
                        </div>

                        <button @click="toggleCompare" class="compareBtn">{{ $t("Compare talent") }}</button>
                    </div>

                    <div class="score-applicants">
                        <div>
                            <CandidateCard2 :passed="true" :backgroundHeader="[1]" :candidate="bestCandidate" :candidateInfo="candidateInfo" />
                        </div>
                        <div class="individualScoreWrapper">
                            <IndividualScore :candidateScore="bestCandidate?.results" />
                        </div>
                    </div>
                    <div class="candidatesChartWrapper">
                        <candidatesChart :histogramData="this.histogramData" />
                        <div v-if="chartData[0]?.results?.length > 2" class="flex flex-col">
                            <div class="flex flex-row content-start items-center w-[100%] p-5">
                                <div class="svgBG">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                        <path
                                            d="M24.375 24.375H17.875V3.25H24.375V24.375ZM19.5 22.75H22.75V4.875H19.5V22.75ZM16.25 24.375H9.75V9.75H16.25V24.375ZM8.125 24.375H1.625V14.625H8.125V24.375Z"
                                            fill="#00AEF0"
                                        />
                                    </svg>
                                </div>
                                <h3>{{ $t("Skills") }}</h3>
                            </div>

                            <SpiderwebChart :data="chartData" :labels="chartLabels" />
                        </div>
                        <div v-else class="flex flex-col">
                            <div class="flex flex-row content-start items-center w-[100%] p-3">
                                <div class="svgBG">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                        <path
                                            d="M24.375 24.375H17.875V3.25H24.375V24.375ZM19.5 22.75H22.75V4.875H19.5V22.75ZM16.25 24.375H9.75V9.75H16.25V24.375ZM8.125 24.375H1.625V14.625H8.125V24.375Z"
                                            fill="#00AEF0"
                                        />
                                    </svg>
                                </div>
                                <h3>{{ $t("Skills") }}</h3>
                            </div>
                            <EmptySpyderWebChart />
                        </div>
                    </div>

                    <div class="calendar-candidate">
                        <div class="applicants">
                            <div style="margin-top: 5%" class="flex flex-row content-center items-center gap-2">
                                <div class="svgBG">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                        <path
                                            d="M8.53464 17.7958L12.2096 15.5792L15.8846 17.825L14.9221 13.625L18.1596 10.825L13.9013 10.4458L12.2096 6.47917L10.518 10.4167L6.25964 10.7958L9.49714 13.625L8.53464 17.7958ZM5.00547 22.6667L6.9013 14.4708L0.542969 8.95833L8.94297 8.22917L12.2096 0.5L15.4763 8.22917L23.8763 8.95833L17.518 14.4708L19.4138 22.6667L12.2096 18.3208L5.00547 22.6667Z"
                                            fill="#FBBC05"
                                        />
                                    </svg>
                                </div>
                                <span class="recentapp">{{ $t("Rating") }}</span>
                            </div>

                            <div class="recentcandidates" v-if="candidatesRating.length > 0">
                                <CandidatesRating v-for="(candidateRating, i) in candidatesRating" :candidateRating="candidateRating" :key="i" />
                            </div>
                            <div v-else class="flex content-center items-center w-full">
                                <div class="w-full noRating">
                                    <span>{{ $t("No Ratings Yet") }}</span>
                                </div>
                            </div>
                            <!-- <button class="viewbtn">
                            {{ $t("View All") }}
                            <img loading="lazy"  decoding="async" src="../assets/Images/icons/Vector.svg" alt="" />
                            </button> -->
                        </div>

                        <div class="score">
                            <Destribution :diskData="diskData" :project="project" :show="candidateInfo ? true : false" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CandidatesRating from "../components/CandidatesRating.vue";
import Destribution from "@/components/Destribution.vue";
// import LineChart from "../charts/Graph.vue";
import CandidateCard2 from "@/components/CandidateCard2.vue";
import IndividualScore from "@/components/IndividualScore.vue";
import candidatesChart from "@/components/candidatesChart.vue";
import SpiderwebChart from "../charts/SpiderwebChart.vue";
import EmptySpyderWebChart from "../charts/EmptySpyderWebChart.vue";
import InviteTeammate from "@/components/InviteTeammate.vue";
import { useStore } from "../store/index";
import CompareModal from "@/components/CompareModal.vue";
import LoadingComponent from "../components/LoadingComponent.vue";
import axios from "axios";

export default {
    name: "ActivityView",
    components: {
        CompareModal,
        // RacentCandidateRow2,
        IndividualScore,
        // LineChart,
        Destribution,
        CandidateCard2,
        candidatesChart,
        SpiderwebChart,
        InviteTeammate,
        CandidatesRating,
        EmptySpyderWebChart,
        LoadingComponent,
    },
    props: ["showActivity", "toggleActivity", "candidate"],
    setup() {
        const Store = useStore();
        return { Store };
    },
    data() {
        return {
            diskData: [],
            compareModal: false,
            showEmail: false,
            companyAssessments: [],
            candidateInfo: "",
            bestCandidate: "",
            isLoading: true,
            chartData: [
                {
                    name: "RAOUF",
                    results: [
                        {
                            assessmentName: "Watson Glaser Critical Thinking",
                            totalPoints: 4,
                            quesionsNbr: 6,
                        },
                        {
                            assessmentName: "Time management",
                            totalPoints: 1,
                            quesionsNbr: 3,
                        },
                        {
                            assessmentName: "Problem solving",
                            totalPoints: 8,
                            quesionsNbr: 12,
                        },
                    ],
                },
            ], // Example data values
            chartLabels: ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"], // Example labels
            project: "",
            graphData: [
                // Provide your dataset here
                { Date: new Date("2023-01-01"), Close: 20 },
                { Date: new Date("2023-01-02"), Close: 60 },
                { Date: new Date("2023-01-03"), Close: 30 },
                { Date: new Date("2023-01-04"), Close: 50 },
            ],
            histogramData: [],
            candidateScore: [
                {
                    title: "java",
                    score: 94,
                },
                {
                    title: "javaScript",
                    score: 80,
                },
                {
                    title: "python",
                    score: 65,
                },
                {
                    title: "leaderShip",
                    score: 85,
                },
                {
                    title: "communication",
                    score: 2,
                },
            ],
            selectedAssessment: "",
            candidatesRating: [],
        };
    },
    methods: {
        toggleEmail() {
            this.showEmail = !this.showEmail;
        },
        toggleCompare() {
            this.compareModal = !this.compareModal;
            this.Store.transformEvaluations(this.project);
        },
        calculateScore() {
            let score = 0;
            this.bestCandidate?.results?.forEach((element) => {
                score += (element?.totalPoints * 100) / element?.quesionsNbr;
            });
            const averageScore = score / this.bestCandidate?.results?.length;
            const roundedScore = averageScore.toFixed(2);
            return roundedScore;
        },
        getBestCandidate(id) {
            if (id === "") {
                return;
            }
            this.histogramData = [];
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/company/bestCandidate/${id}`,
                headers: {},
            };
            this.isLoading = true;
            axios
                .request(config)
                .then(async (response) => {
                    this.bestCandidate = response.data.bestCandidate;
                    this.candidateInfo = response.data.candidateInfo;
                    this.histogramData = response.data.candidatesScore;

                    this.chartData = [
                        {
                            name: this.candidateInfo?.First_name + " " + this.candidateInfo?.Last_name,
                            results: this.bestCandidate?.results,
                        },
                        {
                            name: "Project Average",
                            results: response.data.averageData,
                        },
                        // ...response.data.averageData,
                    ];
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.candidateInfo = "";
                    console.log(error);
                    this.isLoading = false;
                });
            this.diskData = [];
            this.diskData = this.Store.createDistributionData(id);

            let config2 = {
                method: "get",
                maxBodyLength: Infinity,
                url: `https://server.go-platform.com/AssessmentTest/candidateRating/${id}`,
                headers: {},
                withCredentials: true,
            };
            axios
                .request(config2)
                .then((response) => {
                    this.candidatesRating = response.data;
                })
                .catch((error) => {
                    console.log({ error });
                });
        },
    },
    watch: {
        project: function (val) {
            this.getBestCandidate(val);
        },
    },
    async mounted() {
        await this.Store.fetchProjects();
        this.Store.fetchCandidates();
        this.Store.getCompanyAssessments();
        this.Store.fetchInvitations();
        this.project = this.Store.projects[0]?._id;
        this.getBestCandidate(this.Store.projects[0]?._id);
        this.Store.getDistributionData(this.Store.projects[0]?._id);
        this.isLoading = false;
    },
};
</script>

<style scoped lang="scss">
.activityPageWrapper {
    width: 100%;
    height: 100%;

    .loader {
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.activityContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
    // z-index: 10;

    > :nth-child(2) {
        padding: 24px 8px;
        width: 100%;
        height: 90%;
        background-color: #f4f7fe;
        overflow-y: scroll;
        z-index: 20;
        border-radius: 20px;
    }
}

.text-top {
    h2 {
        color: #0f172a;
        font-family: Roboto;
        font-size: 36px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        /* 116.667% */
        letter-spacing: -0.72px;
    }

    button {
        color: var(--secondary-primary-white, #fff);
        text-align: center;
        font-family: DM Sans;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        /* 109.091% */
        letter-spacing: -0.44px;
        display: flex;
        width: 166px;
        height: 48px;
        padding: 10px 27px 14px 25px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 24px;
        background: #2196f3;
        cursor: pointer;
        transition: 0.2s ease-in;

        &:hover {
            &:hover {
                opacity: 0.85;
            }
        }
    }
}

button:hover {
    opacity: 0.9;
}

.filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 5% 0;
}

.select {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid rgba(204, 215, 236, 0.5);
    // padding: 0.5% 0 0.5% 1%;
    width: 15rem;
    background-color: transparent !important;

    > :nth-child(2) {
        width: 70%;
        // float: right;
        right: 0;
        border: none;
        background: transparent;

        :nth-child(2) {
            border: none;
            background: transparent;
        }

        &:focus {
            outline: none !important;
        }
    }

    .select_label {
        // position: absolute;
        // top: 50%;
        // left: 5%;
        // z-index: 5;
        padding: 0 1% 0 0;
        // transform: translateY(-50%);
        pointer-events: none;
        // background: #fff;
        transition: all 0.3s ease;
    }
}

.activityContent {
    width: 100%;
    margin-top: 0;
}

.activityContent::-webkit-scrollbar-track {
    background: red;
    // display: none;
}

/* Handle */
.activityContent::-webkit-scrollbar-thumb {
    background: red;
    // display: none;
}

.grid-row-widgets {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}

.widget {
    background: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
}

.widget-info {
    text-align: left;
}

.widget-title {
    text-align: left;
    font-size: 140px;
    font-weight: 700;
    font-size: 16px;
    font-weight: 900;
    color: #7d8fb3;
    margin-bottom: 8px;
}

.compareBtn {
    background: #2196f3;
    border-radius: 20px;
    width: 156px;
    height: 46px;
    padding: 10px 22px;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    margin: auto 0rem auto 6rem;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.32px;
    transition: all 0.2s ease-in-out;

    &:hover {
        font-size: 14px;
    }
}

.background-1 {
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
}

.background-1 > div > .widget-info {
    color: #fff;
}

.background-1 > div > .widget-title {
    color: #ffffff;
    font-weight: 900;
}

.background-1 > div > .widget-info {
    color: #fff;
}

.background-1 > div > .widget-title {
    color: #e9edf7;
}

.background-2 {
    background: linear-gradient(135deg, #fab2ff 0%, #2196f3 100%);
}

.background-2 > div > .widget-info {
    color: #fff;
}

.background-2 > div > .widget-title {
    color: #ffffff;
    font-weight: 900;
}

.background-2 > div > .widget-title {
    color: #fff;
}

.widget-title {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #a3aed0;
    margin-bottom: 8px;
    font-weight: 700;
}

.widget-info {
    display: block;
    font-weight: 700;
    font-size: 28px;
    color: #1b2559;
}

.score-applicants {
    width: 100%;
    display: grid;
    grid-template-columns: 38% 60%;
    margin: 36px 0px 0 0;
    gap: 2%;

    //   & > :first-child {
    //     padding: 2%;
    //     height: 150%;
    //     width: 100%;
    //   }
    > :first-child {
        width: 100%;
        border-radius: 20px;
        padding: 10px 0;
        background-color: #fff;
        z-index: 5;
    }
}

.score {
    display: grid;
    height: 420px;
    width: 100%;
    align-items: center;
    grid-template-rows: 1fr 2fr 3fr;
    background-color: #fff;
    border-radius: 20px;
    padding: 10px 16px 0 16px;
}

.applicants {
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 16px;
    display: flow-root;
    text-align: left;
}

.candidatesChartWrapper {
    width: 100%;
    border-radius: 20px;
    // padding: 16px;
    // display: flow-root;
    text-align: left;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 2%;

    > :nth-child(2) {
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.stat-widget {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding: 0px 48px;
}

.stat-widget > div {
    box-shadow: 0px 18px 40px rgb(112 144 176 / 12%);
    padding: 8px 16px;
    display: grid;
    grid-template-rows: 1fr 2fr;
    align-items: center;
    background: #fff;
    border-radius: 20px;
}

.stat-widget > div > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.avg {
    display: flow-root;
}

.calendar-candidate {
    width: 100%;
    display: grid;
    grid-template-columns: 30% 65%;
    gap: 20px;
    margin: 20px 0px;
}

.calendar-candidate > button {
    border-radius: 24px;
    color: #fff;
    right: -215%;
    width: 90%;
    padding: 10px;
    position: relative;
    margin-top: 15%;
    margin-bottom: 30%;
}

.calendar {
    height: 350px;
    display: flow-root;
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
    padding-top: 5%;

    img {
        width: 33px;
        height: 33px;
    }
}

.calendar > button {
    margin: 3% 0% 3% 63%;
}

.candidate {
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 16px;
}

.candidate > button {
    margin-top: 2%;
    margin-left: 83%;
}

.calendar-candidate > button:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
    text-decoration: none !important;
}

.score-head {
    display: grid;
    justify-items: baseline;
    align-items: center;
    grid-template-columns: 5fr 2fr;
    margin: 3% 0;

    & > :first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        h3 {
            color: #a3aed0;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.score-head > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: baseline;
    align-items: center;
}

.score-head > div > span {
    color: #1b2559;
}

.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}

.score-head > select:focus {
    border: none;
    outline: none;
}

.score-head > img {
    justify-self: end;
    height: 33px;
    height: 33px;
}

.recentapp {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
}

.individualScoreWrapper {
    background-color: #fff;
    border-radius: 20px;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
}

.viewbtn-2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    background: red;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.recentcandidates {
    display: grid;
    padding: 5% 0%;
    gap: 10px;
}

.tasks {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
    display: grid;
    background-color: #fff;
    border-radius: 20px;
    margin: 10% 10% 10% 2%;
    padding: 0% 3%;
}

.tasks > div {
    border-left: 3px solid #4318ff;
    display: grid;
    gap: 3px;
    justify-items: left;
    padding-left: 3%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.thedate {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 42px;
    /* identical to box height, or 124% */

    letter-spacing: -0.02em;
}

.time-stamp {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #a3aed0;
}

.thetask {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1b2559;
}

.tasks > div:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
}

.on-track {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 34px;
    color: #1b2559;
}

.skills {
    border-radius: 20px;

    > :nth-child(2) {
        border: none;
    }
}

.select {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccd7ec;
    border-radius: 6px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 15%);
    padding: 1rem;
    width: 33%;
    background-color: white !important;

    > :nth-child(2) {
        width: 50%;
        // float: right;
        right: 0;
        border: none;
        background: transparent;

        :nth-child(2) {
            border: none;
            background: transparent;
        }

        &:focus {
            outline: none !important;
            // width: fit-content;
            border: none;
        }
    }

    .select_label {
        // position: absolute;
        // top: 50%;
        // left: 5%;
        // z-index: 5;
        padding: 0 1% 0 0;
        // transform: translateY(-50%);
        pointer-events: none;
        // background: #fff;
        transition: all 0.3s ease;
        text-wrap: nowrap;
    }
}

.title {
    // width: 96%;
    padding-right: 2rem;
    text-align: left;
    margin-right: 0;
    color: var(--secondary-grey-900, #0f172a);
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 116.667% */
    letter-spacing: -0.72px;

    &::after {
        content: "|";
        height: 40px;
        width: 2px;
        color: #656669;
        margin-left: 30px;
        opacity: 0.2;
    }
}

.noCandidates {
    // h2 {
    //     color: #0f172a;
    //     font-family: Roboto;
    //     font-size: 36px;
    //     font-style: normal;
    //     font-weight: 300;
    //     line-height: 42px;
    //     letter-spacing: -0.72px;
    // }

    // button {
    //     color: var(--secondary-primary-white, #fff);
    //     text-align: center;
    //     font-family: DM Sans;
    //     font-size: 22px;
    //     font-style: normal;
    //     font-weight: 700;
    //     line-height: 24px;
    //     /* 109.091% */
    //     letter-spacing: -0.44px;
    //     display: flex;
    //     width: 166px;
    //     height: 48px;
    //     padding: 10px 27px 14px 25px;
    //     justify-content: center;
    //     align-items: center;
    //     flex-shrink: 0;
    //     border-radius: 24px;
    //     background: #2196f3;
    //     cursor: pointer;
    //     transition: 0.2s ease-in;

    //     &:hover {
    //         &:hover {
    //             opacity: 0.85;
    //         }
    //     }
    // }

    .secondText {
        color: #0f172a;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 42px;
        /* 175% */
        letter-spacing: -0.48px;

        img {
            width: 35%;
        }

        h1 {
            color: #0f172a;
            text-align: center;
            font-family: Roboto;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px;
            /* 87.5% */
            letter-spacing: -0.96px;
            margin: 2% 0;
        }

        h3 {
            // margin: 2% 0;
        }

        span {
            color: #0f172a;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 42px;
            letter-spacing: -0.48px;
        }
    }
}

.title2 {
    // margin: 3% 0;
    color: #0f172a;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 42px;
    /* 116.667% */
    letter-spacing: -0.72px;
}

.svgBG {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    flex-shrink: 0;
    background-color: var(--Secondary-Grey-300, #f4f7fe);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

h3 {
    color: #1b2559;
    font-family: DM Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.4px;
    margin: 0;
}

.noRating {
    margin: 40% 0;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: 300;

    span {
        color: #1b2559;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 160% */
        letter-spacing: -0.4px;
        width: 90%;
    }
}
</style>
