<template>
    <div id="test" class="container">
        <div class="navigation-bar">
            <NavgationTabsView />
        </div>

        <div class="task-stat">
            <div class="invitation">
                <div class="big-text">
                    {{ $t("Hi Johnson, Add your Team to sync your work.") }}
                    <span> {{ $t("Please invite your team to setup your account completly.") }}</span>
                </div>

                <button class="invitebtn">{{ $t("Invite Team") }} ></button>
                <InviteCandidate :show-email="true" :project-id="project" />
            </div>
            <hr />

            <div class="stats">
                <div class="stat-card">
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/CompletedTasksIcon.svg" alt="" />
                    <div>
                        <span class="card-title"> {{ $t("Completed Tasks") }}</span>
                        <span class="card-info"> 10.2K</span>
                    </div>
                </div>

                <div class="stat-card">
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/AssignedTasksIcon.svg" alt="" />
                    <div>
                        <span class="card-title"> {{ $t("Assigned Tasks") }}</span>
                        <span class="card-info"> 3.4K</span>
                    </div>
                </div>

                <div class="stat-card">
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/AllBoardsIcon.svg" alt="" />
                    <div>
                        <span class="card-title"> {{ $t("All Boards") }}</span>
                        <span class="card-info"> 450</span>
                    </div>
                </div>

                <div class="stat-card">
                    <img loading="lazy" decoding="async" src="../assets/Images/icons/ScheduledTasksIcon.svg" alt="" />
                    <div>
                        <span class="card-title"> {{ $t("Scheduled Tasks") }}</span>
                        <span class="card-info"> 23</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="task-chart">
            <SunburstChart :diskData="this.diskData" />
            <taskDone />
        </div>

        <div class="team-performance">
            <div class="team-header">
                <span class="big-text"> {{ $t("My Team Performances") }}</span>
                <span class="little-text">{{ $t("Teams With Task Graph Analysis") }}</span>
            </div>
            <div class="graph-members">
                <div class="members">
                    <span v-for="member in members" :key="member">
                        <hr class="border" :style="{ 'background-color': member.color }" />
                        {{ member.name }}
                    </span>
                </div>
                <div class="performance-graph">
                    <LineChart :graphData="this.graphData" :chartWidth="750" :chartHeight="300" />
                </div>
            </div>
        </div>

        <button class="viewbtn">{{ $t("Upgrade to PRO to get access to all features!") }}</button>
    </div>
</template>

<script>
/*import HomeVue from '../components/LineChart.vue'*/
//import CanvasJSChart from "../CanvasJSVueComponent.vue"
import taskDone from "../components/LineChart-taskDone.vue";
import SunburstChart from "../charts/Sunburst.vue";
import LineChart from "../charts/Graph.vue";
import NavgationTabsView from "@/components/NavgationTabsView.vue";
import InviteCandidate from "@/components/InviteCandidate.vue";

export default {
    name: "BoardsView",
    components: {
        taskDone,
        SunburstChart,
        LineChart,
        NavgationTabsView,
        InviteCandidate,
        /* HomeVue , CanvasJSChart*/
    },
    data() {
        return {
            project: "",
            members: [
                { name: "Amine", color: "#2196f3" },
                { name: "Sara", color: "#33BFFF" },
                { name: "Slimane", color: "#FFCB33" },
                { name: "Samira", color: "#29CC39" },
            ],
            diskData: [
                { label: "Data A", value: 30 },
                { label: "Data B", value: 50 },
                { label: "Data C", value: 20 },
            ],
            graphData: [
                // Provide your dataset here
                { Date: new Date("2023-01-01"), Close: 20 },
                { Date: new Date("2023-01-02"), Close: 10 },
                { Date: new Date("2023-01-03"), Close: 60 },
                { Date: new Date("2023-01-04"), Close: 30 },
                { Date: new Date("2023-01-05"), Close: 80 },
                { Date: new Date("2023-01-06"), Close: 90 },
                { Date: new Date("2023-01-07"), Close: 10 },
                { Date: new Date("2023-01-08"), Close: 30 },
            ],
        };
    },
    methods: {
        // chartInstance(chart) {
        //   this.chart = chart;
        // },
    },
    mounted() {
        this.project = this.$route.query.id;
    },
};
</script>

<style scoped>
.container {
    /* display: grid;
    grid-template-rows: 10% 45% 45%;
    gap: 20px;
    height: fit-content;*/
    padding: 24px 8px;
}

.task-stat {
    background-color: #fff;
    display: grid;
    height: fit-content;
    border: 0.6px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    padding: 2%;
    padding-bottom: 0%;
    padding-left: 1%;
}

.invitation {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.invitation > div {
    display: grid;
    padding: 0%;
    padding-left: 5%;
}

.invitation > div > span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.01em;
    color: #5c5f62;
}

.invitebtn {
    width: fit-content;
    height: 75%;
    justify-self: flex-end;
    align-self: center;
    border: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    background: #2196f3;
    border-radius: 20px;
    color: #fff;
}

hr {
    width: 90%;
    background-color: #cdcdcd;
    height: 1%;
    margin-bottom: 0%;
    margin-top: 2%;
}

.stats {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.stat-card {
    background: #fff;
    grid-template-columns: 1fr 2fr;
    display: grid;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
}

.card-title {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #a3aed0;
    margin-bottom: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #5c5f62;
}

.card-info {
    display: block;
    font-weight: 700;
    font-size: 24px;
    color: #1b2559;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -0.01em;
    color: #000000;
}

.task-chart {
    height: fit-content;
    display: grid;
    grid-template-columns: 40% 58%;
    gap: 20px;

    padding: 5% 0%;
}

.little-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #5c5f62;
}

.team-performance {
    background-color: #fff;
    display: grid;
    grid-template-rows: 1fr 3fr;
    background: #fefeff;
    border: 0.6px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.03);
    border-radius: 10px;

    padding: 2% 0% 5% 4%;
}

.team-header {
    display: grid;
}

.graph-members {
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.members {
    display: grid;
    border-right: 1px solid rgba(0, 0, 0, 0.14);
    width: 90%;
    margin-top: 5%;
    gap: 10px;
    justify-items: baseline;
    padding-left: 0px;
}

.members > span {
    border-radius: 10px;
    align-items: center;
    display: flex;
    /* width: 80%; */
}

.members > span:visited {
    background-color: #2196f3;
    color: #fff;
}

.performance-graph {
    margin: 0% 1%;
    padding: 0%;
    padding-right: 1%;
}

.performance-graph > img {
    width: 100%;
}

.big-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #000000;
}

.border {
    transform: rotate(90deg);
    width: 23%;
    margin: 0%;
    border: 20px;
    height: 2px;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
    border-radius: 24px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
    right: -35%;
    width: 30%;
    padding: 5% 3%;
    position: relative;
    margin-top: 6%;
    margin-bottom: 30%;
}

.viewbtn:hover {
    text-decoration: underline #fff;
}
</style>
